import React, { useState, useContext, useEffect } from 'react';
import { Container, Col, Row, Button, Form, FormGroup, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, NavLink } from 'reactstrap';
import { GearIcon } from '@primer/octicons-react'

import { DeviceMotionProvider } from './DeviceMotion';
import { APIState } from '../State/APIClient';
import { SettingsState } from '../State/Settings';
import { Link } from 'react-router-dom';
import { NeedData } from '../Prerequisites/NeedData';
import { BuzzEvent } from '../API';
import { audioNodes } from '../Prerequisites/SoundCheck';

export const SmolCol: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return <Col xs="12" sm={{ offset: 3, size: 6 }}>
        {children}
    </Col>
}

export const Buzzer: React.FC<{ onBuzz: () => void }> = ({ onBuzz }) => {
    let api = useContext(APIState)
    if (!api.clientState || !api.serverState)
        return <></>
    let team = api.clientState.ownTeam
    let buzzerState = api.serverState.buzzersEnabled ? 'ENABLED' : 'DISABLED'
    if (api.serverState.cooldown !== undefined)
        buzzerState = team.buzzedDuringCooldown ? 'TOO_LATE' : 'COOLDOWN'
    if (team.excluded || team.isLobby)
        buzzerState = 'EXCLUDED'
    if (!api.serverState.buzzersEnabled && api.serverState.lastWinner === api.clientState.ownTeam.uuid)
        buzzerState = 'WINNER'

    return <Button
        block
        size="lg"
        style={{
            height: '3.5em',
            fontSize: '2.2em',
            backgroundColor: buzzerState === 'ENABLED' ? '#985f0d' : buzzerState === 'WINNER' && api.serverState.cooldown !== undefined ? 'rgb(17 191 31)' : undefined
        }}
        disabled={buzzerState !== 'ENABLED'}
        onClick={onBuzz}>
        {buzzerState === 'DISABLED' ? 'DISABLED' : null}
        {buzzerState === 'EXCLUDED' ? 'EXCLUDED' : null}
        {buzzerState === 'TOO_LATE' ? 'TOO LATE' : null}
        {buzzerState === 'ENABLED' ?  'BUZZ!!!!!' : null}
        {buzzerState === 'COOLDOWN' ? (team ? api.serverState.cooldown + 's' : '?') : null}
        {buzzerState === 'WINNER' ? 'YOU BUZZED' + (api.serverState.cooldown !== undefined ? ` (${api.serverState.cooldown}s)` : '') : null}
    </Button>
}

export const BuzzerView: React.FC = () => {
    let settings = useContext(SettingsState)
    let api = useContext(APIState)
    let [settingsDropdown, setSettingsDropdown] = useState(false);

    let ownTeam = api.clientState && api.clientState.ownTeam
    const ownUuid = ownTeam && ownTeam.uuid
    useEffect(() => {
        if (api.platform === null || settings.session === null)
            return
        api.platform.buzzHandler = (event: BuzzEvent) => {
            if (event.teamUuid === ownUuid)
                audioNodes.buzzer?.play()
        }
    }, [ownUuid, api.platform, settings.session])


    if (api.clientState === null)
        return <NeedData />

    let team = api.clientState.ownTeam

    let onBuzz = () => {
        if (api.platform) {
            console.log("Sending BuzzRequest!")
            api.platform.send({
                kind: "Buzz",
                session: settings.session!
            })
        }
    }

    return (
        <Container>
            <Row style={{ paddingBottom: '1em' }}>
                <SmolCol>
                    <Dropdown style={{ float: 'right' }} toggle={() => setSettingsDropdown(!settingsDropdown)} isOpen={settingsDropdown}>
                        <DropdownToggle>
                            <GearIcon size="medium" />
                        </DropdownToggle>
                        <DropdownMenu end>
                            <DropdownItem><NavLink tag={Link} to="/teams">Select Team</NavLink></DropdownItem>
                            {/* TODO: <DropdownItem>View Score</DropdownItem> */}
                            <DropdownItem><NavLink tag={Link} to="/configure">Configure Buzzer</NavLink></DropdownItem>
                            <DropdownItem><NavLink tag={Link} to="/logout">Logout</NavLink></DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </SmolCol>
            </Row>
            <Row style={{ paddingBottom: '1em' }}>
                <SmolCol>
                    <Form style={{ textAlign: 'left', color: "white" }}>
                        <FormGroup>
                            <Label>Name:</Label>
                            <div style={{ backgroundColor: '#4e5d6c', padding: '9px' }}>
                                {api.clientState.deviceName}
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label>Team:</Label>
                            <div style={{ backgroundColor: '#4e5d6c', padding: '9px' }}>
                                {team.name}
                                <span style={{ textAlign: 'right', float: 'right' }}>
                                    {team.score}
                                    {team.score === 1 ? ' point' : ' points'}
                                </span>
                            </div>
                        </FormGroup>
                    </Form>
                </SmolCol>
            </Row>
            <Row >
                <SmolCol>
                    <DeviceMotionProvider sensitivity={settings.sensitivity} onBuzz={onBuzz}>
                        <Buzzer onBuzz={onBuzz} />
                    </DeviceMotionProvider>
                </SmolCol>
            </Row>
        </Container >
    )
}