export interface ResponsePacket {
  id: number,
  response?: ResponsePayload,
  streamVal?: any,
  error?: string,
}

export interface RequestPacket {
  id: number,
  payload: RequestPayload
}

export type RequestPayload = KeepAliveRequest | SetBuzzersEnabledRequest | UpdateSettingsRequest | SpawnInstanceRequest | DeleteInstanceRequest
  | StreamClientStateRequest | BuzzRandomTeamRequest | ResetPointsRequest | StreamServerStateRequest | StreamSettingsRequest | StreamBuzzFeedRequest
  | JoinInstanceRequest | BuzzRequest | SetPointsRequest | DeleteTeamRequest | RenameDeviceRequest | RenameTeamRequest | CreateTeamRequest | ChangeExclusionRequest
  | JoinTeamRequest;

export type ResponsePayload = { kind: "success" } | JoinInstanceResponse | SpawnInstanceResponse

export type Timestamp = { t: string }
export function dateToTs(ts: Date): Timestamp {
  return { t: ts.toJSON() }
}
export function tsToDate(ts: Timestamp): Date {
  return new Date(ts.t)
}


export interface AdminSettings {
  timeout: number,
  rearmAfter: number,
  autoReenable: boolean,
  soundEnabled: boolean,
  allowTeamChanging: boolean,
}


export interface BuzzEvent {
  teamUuid: string;
  timestamp: Timestamp;
}

export interface Device {
  uuid: string;
  name: string;
  lastSeenTimestamp: Timestamp;
}

export interface Team {
  uuid: string;
  name: string;
  excluded: boolean;
  buzzedDuringCooldown: boolean;
  devices: { [key: string]: Device };
  isLobby: boolean;
  score: number;
}

export interface ClientState {
  ownTeam: Team;
  deviceName: string;
}

export interface ServerState {
  teams: Team[];
  cooldown?: number;
  lastWinner?: string;
  buzzersEnabled: boolean;
  instanceId: string;
  enableTimestamp?: Timestamp;
  buzzOrder: { teamUuid: string, timestamp: Timestamp }[];
}


// RPC Endpoints:

export interface StreamClientStateRequest {
  kind: "StreamClientState",
  session: string
}

export interface StreamServerStateRequest {
  kind: "StreamServerState",
  instance?: string,
  session?: string,
}

export interface StreamSettingsRequest {
  kind: "StreamSettings",
  instance?: string,
  session?: string,
}

export interface StreamBuzzFeedRequest {
  kind: "StreamBuzzFeed",
  instance?: string,
  session?: string,
}

export interface KeepAliveRequest {
  kind: "KeepAlive",
  session?: string,
  noop?: boolean,
}

export interface SetBuzzersEnabledRequest {
  kind: "SetBuzzersEnabled",
  session: string,
  enabled: boolean,
}

export interface UpdateSettingsRequest {
  kind: "UpdateSettings",
  session: string,
  settings: AdminSettings,
}
export interface SpawnInstanceRequest {
  kind: "SpawnInstance",
  instance: string,
  authKey: string;
}

export interface SpawnInstanceResponse {
  kind: "SpawnInstance",
  session: string,
}

export interface DeleteInstanceRequest {
  kind: "DeleteInstance",
  session: string;
}

export interface BuzzRandomTeamRequest {
  kind: "BuzzRandomTeam",
  session: string,
}

export interface ResetPointsRequest {
  kind: "ResetPoints",
  session: string;
}

export interface JoinInstanceRequest {
  kind: "JoinInstance",
  instance: string,
  name?: string,
  authKey?: string,
}

export interface JoinInstanceResponse {
  kind: "JoinInstance",
  session: string,
}

export interface BuzzRequest {
  kind: "Buzz",
  session: string,
  // spicy
  teamId?: string,
}

export interface SetPointsRequest {
  kind: "SetPoints",
  session: string,
  teamId: string,
  points: number,
}

export interface DeleteTeamRequest {
  kind: "DeleteTeam",
  session: string,
  teamId?: string,
}

export interface RenameDeviceRequest {
  kind: "RenameDevice",
  session: string,
  newName: string,
  // spicy
  deviceUuid?: string,
}

export interface RenameTeamRequest {
  kind: "RenameTeam",
  session: string,
  newName: string,
  // spicy
  teamId?: string,
}

export interface CreateTeamRequest {
  kind: "CreateTeam",
  session: string,
  name: string,
}

export interface ChangeExclusionRequest {
  kind: "ChangeExclusion",
  session: string,
  teamId: string,
  excluded: boolean,
}

export interface JoinTeamRequest {
  kind: "JoinTeam",
  session: string,
  teamId: string,
  // spicy
  deviceUuid?: string,
}
