import React, { useContext, useEffect, useState } from 'react'
import { APIState } from './State/APIClient'
import { NeedData } from './Prerequisites/NeedData'
import { tsToDate } from './API'

export const BuzzTimings: React.FC = () => {
    let api = useContext(APIState)
    if (!api.serverState)
        return <NeedData />
    
    const serverState = api.serverState

    let events = serverState.buzzOrder.map((event, idx) => {
        const anchor = (idx === 0 ? serverState.enableTimestamp : undefined) || serverState.buzzOrder[0].timestamp
        const elapsed: number = (tsToDate(event.timestamp).getTime() - tsToDate(anchor).getTime()) / 1000
        const rel = Math.round(elapsed * 100) / 100
        const team = api.serverState?.teams.find(team => event.teamUuid === team.uuid)?.name || '<unknown>'
        return {
            team,
            abs: idx === 0 ? rel : undefined,
            rel: idx !== 0 ? rel : undefined,
        }
    })

    return <span style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 'calc(100vh - 20px)'
        }}>
        <span style={{
            fontFamily: 'monospace',
            fontSize: '3em',
        }}>
            <table>
                <tbody>
                    {events.map(event => 
                        <tr key={event.team} style={{
                            color: event.abs !== undefined ? 'white' : 'grey'
                        }}>
                            <td style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '30ch',
                                maxWidth: '30ch',
                            }}>{event.team}</td>
                            {event.abs !== undefined
                            ? <td></td>
                            : <td>+</td>}
                            {event.abs !== undefined
                            ? <td style={{textAlign: 'right'}}>{event.abs}s</td>
                            : <td style={{textAlign: 'right'}}>{event.rel}s</td>}
                        </tr>
                    )}
                </tbody>
            </table>
        </span>
    </span>
}